@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.loader {
  border-top-color: rgb(17, 24, 39);
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
